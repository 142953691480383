<template>
  <div id="block-title">
    <h1 :class="{ts: white}">{{title}}</h1>
    <div class="sub-title">{{subTitle}}</div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    title: String,
    subTitle: String,
    white: Boolean
  }
})
export default class BlockTitle extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
#block-title{
  h1{
    font-size: $title-font-size;
    color: #000;
    text-align: center;
    padding: 0;
    margin: 0;
    position: relative;
    padding-top: 70px;
    padding-bottom: 20px;
    &::after{
      position: absolute;
      display: block;
      content: "";
      width: 48px;
      height: 3px;
      background: $title-red;
      transform: translate(-50%, 0);
      left: 50%;
      margin-top: 5px;
    }
  }
  .sub-title{
    padding-bottom: 20px;
    font-size: 12px;
    line-height: 32px;
    color: #9A9EA1;
  }
  .ts{
    color: #fff !important;
    padding-top: 90px !important;
  }
}
@media (max-width: 767px) {
  #block-title h1{
    font-size: 20px;
    padding-top: 30px;
    padding-bottom: 10px;
  }
}
</style>
