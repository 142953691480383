
import { Options, Vue } from 'vue-class-component'
import BlockTitle from '../public/BlockTitle.vue'
@Options({
  components: {
    BlockTitle
  }
})

export default class Partner extends Vue {}
