
import { Options, Vue } from 'vue-class-component'

@Options({
  data () {
    return {
      blocks: [
        { t: '愿景', icon: 'vision', des: '用活数据，赋能行业' },
        { t: '使命', icon: 'mission', des: '推动社会实现数据驱动转型' },
        { t: '价值观', icon: 'values', des: '服务精准，至臻至简' }
      ]
    }
  },
  methods: {
    getIcon (index: number) {
      return () => `@/assets/about/culture-${this.blocks[index].icon}@2x.png`
    }
  }
})

export default class AboutCultrue extends Vue {}
