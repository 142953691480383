
import { Options, Vue } from 'vue-class-component'
import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'

@Options({
  data () {
    return {
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      scrollTop: 'scrollTop'
    })
  },
  watch: {
    scrollTop: {
      handler (val) {
        this.updateVisible(val, this)
      }
    }
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapMutations({
      updateScrollTop: 'updateScrollTop'
    }),
    updateVisible: _.throttle(function (val, ctx) {
      ctx.visible = val > 1080
    }, 300),
    handleScroll () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.updateScrollTop(scrollTop.toFixed(2))
    },
    clickScrollTop () {
      const element = document.getElementById('home-banner-block')
      element && element.scrollIntoView({ behavior: 'smooth' })
    }
  }
})

export default class ToTop extends Vue {}
