
import { Options, Vue } from 'vue-class-component'
import BannerBlock from '@/components/home/BannerBlock.vue' // @ is an alias to /src
import AboutUs from '@/components/home/AboutUs.vue'
import NlpCase from '@/components/home/NlpCase.vue'
import GisCase from '@/components/home/GisCase.vue'
import Partner from '@/components/home/Partner.vue'
import TestComp from '@/components/TestComp.vue'
import VideoWin from '@/components/public/VideoWin.vue'
import { mapGetters } from 'vuex'

interface Data {
  name: string,
  id: string,
  top: number
}

@Options({
  components: {
    BannerBlock,
    AboutUs,
    NlpCase,
    GisCase,
    Partner,
    TestComp,
    VideoWin
  },
  data () {
    return {
      videoWinVisible: false,
      headerBarItems: [
        { name: '关于我们', id: 'about-us' },
        { name: '智能办公业务', id: 'nlp-case' },
        { name: '时空数据应用', id: 'gis-case' },
        { name: '行业经验', id: 'home-partner' },
        { name: '联系我们', id: 'footer-bar' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      scrollTop: 'scrollTop'
    })
  },
  watch: {
    scrollTop: {
      handler (val) {
        const _topValue = Number(val)
        this.activeTab(_topValue)
      }
    }
  },
  methods: {
    clickEvent () {
      this.$router.push('/about')
    },
    handlePlayVideo (url: string) {
      this.videoWinVisible = true
      console.log(url)
    },
    handleClose () {
      this.videoWinVisible = false
    },
    activeTab (scrollTop: number) {
      let tabsIndex = ''
      let btns = JSON.parse(JSON.stringify(this.headerBarItems))
      btns.map((data: Data) => {
        if (this.$refs[data.id]) {
          data.top = this.$refs[data.id].offsetTop || this.$refs[data.id].$el.offsetTop
        }
      })
      scrollTop += 100 // 补菜单高度，提前切换
      for (let index = 0; index < btns.length; index++) {
        const element = btns[index]
        const nextElement = btns[index + 1]
        if (nextElement) {
          if (scrollTop > element.top && scrollTop < (nextElement.top + element.top) / 2) {
            tabsIndex = btns[index].id
            break
          }
        } else {
          if (scrollTop > element.top) {
            tabsIndex = btns[index].id
            break
          }
        }
      }
      this.currentTab = tabsIndex
      // console.log(tabsIndex)
      this.$emit('activeTab', tabsIndex)
    }
  }
})

export default class Home extends Vue {}
