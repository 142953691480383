
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    tabs: Array
  },
  data () {
    return {
      current: 0
    }
  },
  methods: {
    clickTab (index: number) {
      if (this.current === index) {
        return
      }
      this.current = index
      this.$emit('select-tab', index)
    }
  }
})

export default class CaseTabBar extends Vue {
  tabs!: Array<string>
}
