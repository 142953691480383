
import HeaderBar from './components/HeaderBar.vue'
import FooterBar from './components/FooterBar.vue'
import ToTop from '@/components/public/ToTop.vue'
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'

@Options({
  components: {
    HeaderBar,
    FooterBar,
    ToTop
  },
  data () {
    return {
      msg: '顶栏标题',
      customStyle: {},
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      scrollTop: 'scrollTop'
    })
  },
  watch: {
    scrollTop: {
      handler (val) {
        const _topValue = Number(val)
        if (_topValue > 590) {
          this.customStyle = { background: '#373737', top: '0px', 'border-color': '#ccc' }
          return
        }
        this.customStyle = { top: `-${_topValue}px` }
      }
    },
    $route: {
      handler (val) {
        // console.log('$route', val)
        this.visible = this.$route.name === 'Home'
      },
      deep: true
    }
  },
  methods: {
    handleActiveTab (tab: string) {
      this.$refs.headerBar.activeTab(tab)
    }
  }
})

export default class App extends Vue {}
