<template>
  <div id="test-comp">
    <div class="block manual">
      <span>计数：{{count}}</span>
      <el-button type="primary" plain @click="increase">+ 增加</el-button>
      <el-button type="primary" plain @click="reduce">- 减少</el-button>
    </div>
    <div class="block auto-part">
      <span>计时：{{second}}秒</span>
      <el-button type="primary" plain @click="start">启动</el-button>
      <el-button type="primary" plain @click="stop">停止</el-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  data () {
    return {
      count: 0,
      second: 0,
      interval: null
    }
  },
  mounted () {
    this.start()
  },
  methods: {
    increase () {
      console.log('increase')
      this.count++
    },
    reduce () {
      console.log('reduce')
      this.count--
    },
    start () {
      console.log('start')
      this.interval = setInterval(() => {
        this.second++
      }, 1000)
    },
    stop () {
      console.log('stop')
      clearInterval(this.interval)
    }
  }
})

export default class TestComp extends Vue {}
</script>

<style lang="scss" scoped>
#test-comp{
  max-width: 1080px;
  margin: 0 auto;
  padding: 20px;
  .block{
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span{
      background: burlywood;
      width: 100px;
      height: 28px;
      padding: 0 10px;
      line-height: 28px;
      border-radius: 4px;
      margin-right: 30px;
      text-align: left;
    }
  }
}
</style>
