<template>
  <div id="about-superiority">
    <el-row>
      <el-col :xl="6" :lg="6" :md="12" :sm="12" :xs="24"
        v-for="(item, index) in list"
        :key="index">
        <div class="item" :class="item.class">
          {{item.text}}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  data () {
    return {
      list: [
        {
          text: '先进的数据分析',
          class: 'img-1'
        },
        {
          text: '高效的计算能力',
          class: 'img-2'
        },
        {
          text: '领先的算法模式',
          class: 'img-3'
        },
        {
          text: '丰富的行业经验',
          class: 'img-4'
        }
      ]
    }
  }
})

export default class AboutSuperiority extends Vue {}
</script>

<style lang="scss" scoped>
#about-superiority{
  padding: 30px 0;
  .el-row{
    .el-col{
      .item{
        padding-top: 140px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        transform: translateY(0);
        transition: transform 0.4s;
        &.img-1{
          background: url('@/assets/about/superiority/theory@2x.png') no-repeat center 20px;
        }
        &.img-2{
          background: url('@/assets/about/superiority/arithmetic@2x.png') no-repeat center 20px;
        }
        &.img-3{
          background: url('@/assets/about/superiority/application@2x.png') no-repeat center 20px;
        }
        &.img-4{
          background: url('@/assets/about/superiority/major@2x.png') no-repeat center 20px;
        }
        &:hover{
          transform: translateY(-10px);
          transition: transform 0.4s;
        }
      }
    }
  }
}
</style>
