
import { Options, Vue } from 'vue-class-component'
import BlockTitle from '../public/BlockTitle.vue'
import AboutCompay from '../about/AboutCompany.vue'
import AboutCulture from '../about/AboutCulture.vue'
import AboutSuperiority from '../about/AboutSuperiority.vue'

@Options({
  components: {
    BlockTitle,
    AboutCompay,
    AboutCulture,
    AboutSuperiority
  }
})

export default class AboutUs extends Vue {}
