<template>
  <div id="about-culture">
    <div class="culture-blocks">
      <el-row class="blocks" :gutter="10">
        <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24" v-for="(item, index) in blocks" :key="index">
          <div class="item-block" :class="'item-block-ss' + index">
            <div class="img" :class="`img-${item.icon}`"/>
            <div class="block-title">{{item.t}}</div>
            <div class="line">
              <span></span>
              <span></span>
            </div>
            <div class="des" v-html="item.des"></div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  data () {
    return {
      blocks: [
        { t: '愿景', icon: 'vision', des: '用活数据，赋能行业' },
        { t: '使命', icon: 'mission', des: '推动社会实现数据驱动转型' },
        { t: '价值观', icon: 'values', des: '服务精准，至臻至简' }
      ]
    }
  },
  methods: {
    getIcon (index: number) {
      return () => `@/assets/about/culture-${this.blocks[index].icon}@2x.png`
    }
  }
})

export default class AboutCultrue extends Vue {}
</script>

<style scoped lang="scss">
@import '@/styles/index.scss';
#about-culture{
  padding: 30px 0;
  .culture-blocks{
    .blocks{
      .item-block{
        width: 337px; // 1080下，且gutter为10时，宽度为355
        height: 207px; // 高度是宽度的0.733
        margin: 0px auto;
        margin-top: 58px;
        padding-top: 100px;
        border-radius: 4px;
        box-shadow: 0px 3px 7px 1px rgba(18, 0, 0, 0.2);
        cursor: pointer;
        box-sizing: border-box;
        position: relative;
        .img{
          position: absolute;
          width: 177px;
          height: 177px;
          margin: 0 auto;
          top: -88px;
          left: 50%;
          transform: translateX(-50%);
          // border: 1px dashed #676767;
          &.img-vision{
            background: url(@/assets/about/culture-vision@2x.png) no-repeat center;
            background-size: 100%;
          }
          &.img-values{
            background: url(@/assets/about/culture-values@2x.png) no-repeat center;
            background-size: 100%;
          }
          &.img-mission{
            background: url(@/assets/about/culture-mission@2x.png) no-repeat center;
            background-size: 100%;
          }
        }
        .block-title{
          font-size: 18px;
          margin-top: -18px;
          font-weight: bold;
        }
        .line{
          width: 26px;
          height: 14px;
          margin: 8px auto;
          display: flex;
          justify-content: space-between;
          span{
            height: 8px;
            width: 8px;
            border-radius: 4px;
            background: #E72212;
          }
        }
        .des{
          font-size: $font-size-14;
          line-height: $content-line-height;
          padding: 0 30px;
          color: #676767;
          // display: none;
        }
        &:hover{
          .img{
            top: -88px;
          }
          .block-title{
            margin-top: -18px;
          }
          .line{
            // margin-top: 8px;
          }
          .des{
            display: block;
          }
        }
      }
    }
  }
}
@media(max-width: 1080px){
  #about-culture{
    .culture-blocks{
      .blocks{
        .item-block{
          width: 250px; // 768下，且gutter为10时，宽度为250
          height: 183px; // 高度是宽度的0.733
          .img{
          }
        }
      }
    }
  }
}
@media(max-width: 767px){
  #about-culture{
    margin-bottom: 30px;
    padding: 0 20px 30px 20px;
    .culture-blocks{
      .blocks{
        .item-block{
          margin: 10px auto;
          margin-top: 88px;
          padding-top: 100px;
          .block-title{
            font-size: 18px;
          }
          .des{
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
