<template>
  <div id="gis-case">
    <div class="anchor"></div>
    <case-comp
      :blockTitle="blockTitle"
      :blockDes="blockDes"
      :tabsList="tabsList"
      :tabsContent="tabsContent"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CaseComp from '../public/CaseComp.vue'

@Options({
  components: {
    CaseComp
  },
  data () {
    return {
      blockTitle: '时空数据应用',
      blockDes: '贝业思利用人工智能技术提供时空大数据分析和预测业务，包括气象暴雨相似预报系统、智慧渠道业务系统、城市经济发展模拟系统等。',
      tabsList: ['气象暴雨预报系统', '智慧渠道运营系统', '城市经济发展指数平台'],
      tabsContent: [
        {
          type: 'meteo',
          content: [
            { class: 'title', text: '' },
            { class: 'content', text: ['暴雨往往会造成山洪爆发、泥石流、滑坡、塌方等灾害，当前主要依靠数值模式预报结果及预报员的主观经验对暴雨进行预报和订正，其精准度已不能完全满足精准防灾减灾的需要。'] },
            { class: 'title', text: ['人工智能技术与数值预报相结合，预报准确率显著提高'] },
            { class: 'content has-point', text: ['贝业思利用人工智能技术和相似离度法建设暴雨相似预报系统来提升降水预报预测的精细化水平和准确率；', '系统提供个例收藏、环流形势、相似分析工具，供研究人员对历史个例进行全面的诊断分析。'] }
          ]
        },
        {
          type: 'shdx',
          content: [
            { class: 'title', text: '' },
            { class: 'content', text: ['随着运营商渠道转型升级改造的实施，实体渠道在区域拓展、网络优化（店铺撤迁并改）、店铺经营评估、店铺个性化运营等方面，迫切需要结合多维实时大数据进行提效率，驱动智慧渠道建设。'] },
            { class: 'title', text: ['核心服务能力'] },
            { class: 'content has-point', text: ['结合街区画像数据、产业经济学动态竞争模型和机器学习算法，提供科学、客观、高效的门店选址评估建议，辅助商家决策；', '评估模型融合行业专家知识库，与实际业务更贴合，分析结果更合理；', '该系统广泛适用于连锁企业和线下商家，选址决定经营成败。'] }
          ]
        },
        {
          type: 'nankai',
          content: [
            { class: 'title', text: '' },
            { class: 'content', text: ['贝业思与南开大学合作建设的区域公共政策模拟评估平台，可对政府部门政策实施效果、政府项目投资预测等提供定量化分析评价与咨询服务。平台包括：'] },
            { class: 'title', text: ['5个模拟系统'] },
            { class: 'content has-point', text: ['城市经济发展指数平台包括：城市生活指数、城市营商环境指数、城市房价走势指数、城市薪资水平指数、城市协同发展指数等5大模拟系统。'] },
            { class: 'title', text: ['1个大数据在线分析平台'] },
            { class: 'content has-point', text: ['集教学和科研于一体，广泛适用于开设大数据、人工智能、经管类学科的高校。'] }
          ]
        }
      ]
    }
  }
})

export default class GisCase extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
#gis-case{}
</style>
