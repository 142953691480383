
import { Options, Vue } from 'vue-class-component'
import $ from 'jquery'

@Options({
  data () {
    return {
      items: [
        { name: '关于我们', id: 'about-us' },
        { name: '智能办公业务', id: 'nlp-case' },
        { name: '时空数据应用', id: 'gis-case' },
        { name: '行业经验', id: 'home-partner' },
        { name: '联系我们', id: 'footer-bar' }
      ],
      currentTab: ''
    }
  },
  methods: {
    clickEvent () {
      console.log('click button.')
      this.$router.push('/about')
    },
    switchTab (id: string) {
      this.currentTab = id
      const _offset = $(`#${id} .anchor`).offset()
      if (_offset) {
        const t = _offset.top - 40
        $(window).scrollTop(t)
      }
    },
    activeTab (tab: string) {
      if (!tab) {
        return
      }
      this.currentTab = tab
    }
  }
})
export default class HeaderBar extends Vue {
  title!: string
}
