import { createStore } from 'vuex'

export default createStore({
  state: {
    scrollTop: 0
  },
  mutations: {
    updateScrollTop (state, top) {
      state.scrollTop = top
    }
  },
  actions: {
  },
  getters: {
    scrollTop: (state) => {
      return state.scrollTop
    }
  },
  modules: {
  }
})
