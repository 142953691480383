<template>
  <div id="about-company">
    <div v-if="title" class="who-title">{{title}}</div>
    <el-row class="who">
      <el-col :xl="8" :lg="8" :md="8" :sm="8" :xs="24" class="left hidden-xs-only">
        <img src="@/assets/about/who-is-bayes.png" alt=""/>
      </el-col>
      <el-col :xl="16" :lg="16" :md="16" :sm="16" :xs="24" class="right">
        <div>贝业思成立于2017年，由世界著名大学的经济学家、数据科学家领衔。核心科学家的研究成果多次发表于国际顶尖期刊，在多个行业和领域都有成功案例。核心团队均来源于国内外著名学府，硕博学历人才超过60%。</div>
        <div>贝业思将产业经济学和行为经济学理论作为指导，研究和揭示数据内含的因果和逻辑关系。同时结合最前沿的人工智能计算对数据进行精准挖掘与预测，提供以业务目标为导向的智能数字化解决方案。目前产品应用覆盖气象、金融、通讯、医疗、高校科研等多个行业领域。</div>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: ['title'],
  data () {
    return {
      imgDataCompany: {
        name: 'company',
        inner: 'about/company-question',
        outer: 'about/company-outer-cycle'
      }
    }
  }
})
export default class AboutCompany extends Vue {}
</script>

<style scoped lang="scss">
@import '@/styles/index.scss';
#about-company{
  padding: 30px 0;
  .who-title{
    font-size: 24px;
    color:#354052;
    padding: 0 0 60px 0;
  }
  .who{
    .right{
      text-align: left;
      div{
        font-size: 12px;
        line-height: $content-line-height;
        color: $light-gray-1;
        padding: 10px;
      }
    }
    .left{
      img{
        // width: 300px;
        // height: 256px;
        width: 226px;
        height: 193px;
      }
    }
  }
}
@media(max-width: 1080px){
  #about-company{
    .who{
      .left{
        img{
          margin-top: 20px;
          width: 226px;
          height: 193px;
        }
      }
    }
  }
}
@media(max-width: 767px){
  #about-company{
    padding-bottom: 20px;
    .who{
      margin: 20px 0 20px 0;
      .left{
        background-size: 100%;
      }
      .right{
        padding: 0 20px;
        div{
          font-size: 14px;
          // line-height: $content-des-line-height-mob;
          padding: 20px 0;
        }
      }
    }
  }
}
</style>
