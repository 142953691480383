
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    title: String,
    subTitle: String,
    white: Boolean
  }
})
export default class BlockTitle extends Vue {}
