<template>
  <div id="home-banner-block">
    <el-row class="title-row">
      <el-col :xl="13" :lg="13" :md="13" :sm="12" :xs="24" class="left">
        <div class="mainTitle"><span>数</span>里寻因，<span>据</span>因得果</div>
        <div class="subTitle">贝业思致力于用最前沿的经济学理论和人工智能技术将经济学的因果推断能力与人工智能的预测能力融为一体，并引入行业专家经验，构建灵活高效的数据分析算法，深挖数据价值，提供数字化解决方案。公司利用自然语言处理和深度学习技术聚焦智能办公和时空大数据分析和预测业务。</div>
        <!-- <img src="@/assets/banner/append-pic.svg" class="append-pic-phone hidden-md-and-up"> -->
      </el-col>
      <el-col :xl="11" :lg="11" :md="11" :sm="12" :xs="24" class="right hidden-xs-only">
        <banner-animate class="append-pic"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import BannerAnimate from '@/components/public/BannerAnimate.vue'

@Options({
  components: {
    BannerAnimate
  }
})

export default class BannerBlock extends Vue {}
</script>

<style scoped lang="scss">
@import '@/styles/index.scss';
#home-banner-block{
  padding-top: $header-bar-height;
  // background: #bebebe15;
  .title-row{
    padding: 94px 0px;
    .left{
      margin-top: 60px;
      padding-left: 00px;
      height: 229px;
      .mainTitle{
        font-size: $main-title-size;
        line-height: 70px;
        color:#fff;
        text-align: left;
        margin-bottom: 25px;
        span{
          color: #E1292B;
        }
      }
      .subTitle{
        font-size: 14px;
        line-height: 36px;
        color: #fff;
        text-align: left;
      }
    }
    .right{
      .append-pic{
        width: 80%;
        height: 80%;
      }
    }
  }
}

@media(max-width: 1080px){
  #home-banner-block{
    .title-row{
      padding: 50px 0px;
      .left{
        margin-top: 0;
        padding: 30px 0;
      }
      .showBr{
        display: none;
      }
      .mainTitle{
        font-size: 32px !important;
        line-height: 50px !important;
      }
      .subTitle{
        font-size: 14px !important;
      }
    }
  }
}

@media(max-width:767px){
  #home-banner-block{
    .title-row{
      padding: 0 0 50px 0px;
      .left{
        height: 249px;
        margin-top: 0px;
        padding: 40px 20px 30px 20px !important;
        .mainTitle{
          font-size: 24px !important;
          line-height: 34px !important;
        }
        .subTitle{
          font-size: 14px !important;
          line-height: $content-line-height !important;
          color: #ccc;
        }
      }
      .right{
        .append-pic{
          width: 80%;
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
