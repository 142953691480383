<template>
  <div id="video-win">
    <!-- <i class="close el-icon-close" @click="closeWin"></i> -->
    <video
      class="video-comp"
      autoplay
      controls
      :src="videoURL">浏览器不支持的视频格式</video>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    videoURL: String
  },
  methods: {
    closeWin () {
      this.$emit('close')
    }
  }
})

export default class VideoWin extends Vue {
  videoURL!: string
}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
#video-win{
  width: 100%;
  box-sizing: border-box;
  // background-color: lightgray;
  // border: 5px solid lightgray;
  // border-radius: 5px;
  // position: relative;
  .video-comp{
    width: 100%;
  }
  .close{
    position: absolute;
    top: -15px;
    right: -15px;
    font-size: 20px;
    border-radius: 10px;
    color: white;
    background: lightblue;
    cursor: pointer;
    &:hover{
      font-size: 24px;
      border-radius: 12px;
    }
  }
}
</style>
