<template>
  <div id="img-dialog">
    <el-dialog
      v-model="dialogVisible"
      :fullscreen="true"
      :before-close="handleClose">
      <div class="wrapper" :style="customStyle">
        <img-carousel :caseType="caseType" :carouselHeight="carouselHeight"/>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import ImgCarousel from './ImgCarousel.vue'

@Options({
  components: {
    ImgCarousel
  },
  data () {
    return {
      dialogVisible: false,
      caseType: '',
      customStyle: {},
      carouselHeight: '400px'
    }
  },
  methods: {
    show (type: string) {
      // console.log('get bounds:', window.innerHeight, window.innerWidth)
      let _height = window.innerHeight
      let _width = window.innerWidth
      const _ratioWidth = _height * 12 / 7
      if (_ratioWidth < window.innerWidth) { // 窗口宽度足够
        _width = _ratioWidth
      } else {
        _width = window.innerWidth
        _height = _width * 7 / 12 // 基于窗口宽度，缩小高度
      }
      const _carouselHeight = _height - 100
      _width = _width - 100
      _height = _height - 160

      this.customStyle = { width: `${_width}px`, height: `${_height}px` }
      this.carouselHeight = `${_carouselHeight}px`

      this.caseType = type
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
      this.caseType = ''
    }
  }
})

export default class ImgDialog extends Vue {}
</script>

<style lang="scss" scoped>
#img-dialog{
  .el-dialog{
    .wrapper{
      margin: 10px auto;
    }
  }
}
</style>

<style lang="scss">
@import '@/styles/index.scss';
#img-dialog{
  .el-dialog{
    background-color: #00000055;
    .el-dialog__headerbtn .el-dialog__close{
      color: #fff;
      font-weight: bold;
      font-size: 24px;
      &:hover{
        font-weight: bold;
        color: $title-red;
      }
    }
  }
}
</style>
