
import { Options, Vue } from 'vue-class-component'

@Options({
  data () {
    return {
      count: 0,
      second: 0,
      interval: null
    }
  },
  mounted () {
    this.start()
  },
  methods: {
    increase () {
      console.log('increase')
      this.count++
    },
    reduce () {
      console.log('reduce')
      this.count--
    },
    start () {
      console.log('start')
      this.interval = setInterval(() => {
        this.second++
      }, 1000)
    },
    stop () {
      console.log('stop')
      clearInterval(this.interval)
    }
  }
})

export default class TestComp extends Vue {}
