
import { Options, Vue } from 'vue-class-component'

@Options({
  props: ['title'],
  data () {
    return {
      imgDataCompany: {
        name: 'company',
        inner: 'about/company-question',
        outer: 'about/company-outer-cycle'
      }
    }
  }
})
export default class AboutCompany extends Vue {}
