
<template>
  <div id="home-partner">
    <div class="anchor"></div>
    <block-title title="行业经验" subTitle="已服务于气象、通讯、金融、高校等领域"/>
    <div class="wrap">
      <el-row :gutter="10">
        <el-col class="item" :xs="24" :sm="12" :md="8">
          <div class="bg">
            <img id="telecom" src="@/assets/partner/telecom@2x.png" alt="">
          </div>
        </el-col>
        <el-col class="item" :xs="24" :sm="12" :md="8">
          <div class="bg">
            <img id="sxqxj" src="@/assets/partner/sxqxj@2x.png" alt="">
          </div>
        </el-col>
        <el-col class="item" :xs="24" :sm="12" :md="8">
          <div class="bg">
            <img id="nankai" src="@/assets/partner/nankai@2x.png">
          </div>
        </el-col>
        <!-- <el-col class="item" :xs="24" :sm="12" :md="8">
          <div class="bg">
            <img id="chengjian" src="@/assets/partner/chengjian.png" alt="">
          </div>
        </el-col> -->
        <el-col class="item" :xs="24" :sm="12" :md="8">
          <div class="bg">
            <img src="@/assets/partner/xicai@2x.png">
          </div>
        </el-col>
        <el-col class="item" :xs="24" :sm="12" :md="8">
          <div class="bg">
            <img id="bank" src="@/assets/partner/bank@2x.png" alt="">
          </div>
        </el-col>
        <el-col class="item" :xs="24" :sm="12" :md="8">
          <div class="bg">
            <img id="sxwjw" src="@/assets/partner/sxwjw@2x.png">
          </div>
        </el-col>
        <el-col class="item" :xs="24" :sm="12" :md="8">
          <div class="bg">
            <img src="@/assets/partner/cbrc-bg@2x.png" alt="">
          </div>
        </el-col>
        <!-- <el-col class="item" :xs="24" :sm="12" :md="8">
          <div class="bg">
            <img src="@/assets/partner/cbrc.jpeg" alt="">
          </div>
        </el-col> -->
        <!-- <el-col class="item" :xs="24" :sm="12" :md="8">
          <div class="bg">
            <img src="@/assets/partner/kongfu.png" alt="">
          </div>
        </el-col> -->
        <el-col class="item" :xs="24" :sm="12" :md="8">
          <div class="bg">
            <img id="zsfy" src="@/assets/partner/zsfy@2x.png" alt="">
          </div>
        </el-col>
        <el-col class="item" :xs="24" :sm="12" :md="8">
          <div class="bg">
            <img id="xijiao" src="@/assets/partner/xijiao@2x.png" alt="">
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import BlockTitle from '../public/BlockTitle.vue'
@Options({
  components: {
    BlockTitle
  }
})

export default class Partner extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
#home-partner{
  width: 1080px;
  // margin: 0 atuo !important;
  // padding-bottom: 50px;
  p{
    font-size:$sub-title-font-size;
    color:#000004;
    text-align:center;
    padding-top: 40px;
    padding-bottom: 6px;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .wrap{
    margin-top: 20px;
    .item{
      display: flex;
      justify-content: center;
      .bg{
        width: 350px;
        height: 80px;
        margin: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        box-shadow: 0px 2px 11px 1px rgba(18, 0, 0, 0.2);
      }
      img{
        height: 60px;
        // -webkit-filter: grayscale(1000%);
        // filter: grayscale(100%);
      }
      #telecom{
        height: 44px;
      }
      #sxqxj{
        height: 54px;
      }
      #chengjian{
        height: 40px;
      }
      #nankai{
        height: 54px;
      }
      #bank{
        height: 40px;
      }
      #sxwjw{
        height: 40px;
      }
      #zsfy{
        height: 42px;
      }
      #xijiao{
        height: 54px;
      }
    }
    .cbrc{
      text-align: center;
    }
  }
}

@media(max-width: 1080px) {
#home-partner{
  width:  100%;
    .wrap{
      margin-top: 30px;
      .item{
        .bg{
          width: 350px;
          height: 80px;
          margin: 15px 0;
          img{
          }
          #sxwjw{
            height: 36px;
          }
          #zsfy{
            height: 40px;
          }
        }
      }
    }
  }
}

@media(max-width:767px){
#home-partner{
  width:  100%;
    .wrap{
      margin-top: 30px;
      img{
      }
    }
  }
}
</style>
