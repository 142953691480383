<template>
  <div id="nlp-case">
    <div class="anchor"></div>
    <case-comp
      :blockTitle="blockTitle"
      :blockDes="blockDes"
      :tabsList="tabsList"
      :tabsContent="tabsContent"
      @select-tab="handleSelectTab"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CaseComp from '../public/CaseComp.vue'
import VideoWin from '@/components/public/VideoWin.vue'

@Options({
  components: {
    CaseComp,
    VideoWin
  },
  data () {
    return {
      blockTitle: '智能办公业务',
      blockDes: '贝业思利用最前沿的自然语言处理技术和人工智能技术开发一系列旨在提高效率的智能办公管理协作系统，包括智能统计报告撰写、合同比对、智能流程图绘制、金融合规监管等。',
      tabsList: ['智能统计报告系统', '智能流程系统', '金融合规监管系统'],
      tabsContent: [
        {
          type: 'adambot',
          content: [
            { class: 'title', text: '' },
            { class: 'content', text: ['很多机构和企业需要根据业务数据定期撰写各类统计报告。由于业务数据分散、报告量大，导致投入产出不成比例。', '通过引入智能统计报告系统，企业可以大大提升报告的写作效率。'] },
            { class: 'title', text: ['模板训练与报告撰写'] },
            { class: 'content has-point', text: ['系统基于少量历史报告文件即可通过人工智能算法训练生成报告模板；', '用户导入统计数据完成报告数据计算逻辑设置，一键生成报告。'] },
            { class: 'title', text: ['自动优化模板'] },
            { class: 'content has-point', text: ['系统可根据撰写人员对报告的修改自动完成模板迭代优化，持续提高报告质量。'] }
          ]
        },
        {
          type: 'flowchart',
          content: [
            { class: 'title', text: '' },
            { class: 'content', text: ['智能流程图绘制系统应用领域广泛，可极大减少流程图绘制对人工的依赖，提高绘制效率，改善流程图质量，让工作更加智能化。', '系统可解析历史visio、xml文件，将流程图转化为表格，通过编辑表格绘制流程图，实现表格与流程图实时交互，一键生成标准、规范的流程图，无需人工绘制。'] },
            { class: 'title', text: ['自动解析，智能排版'] },
            { class: 'content has-point', text: ['操作简单，无需人工绘制', '智能排版，无需手动拖拽', '自我学习，模型不断迭代', '企业空间、项目归档功能让文件管理更便捷、协作更高效'] }
          ]
        },
        {
          type: 'law',
          content: [
            { class: 'title', text: '' },
            { class: 'content', text: ['在合规监管领域，金融监管机构在制定监管规则和审查金融机构内部制度和准则时，需要识别是否存在违反法律法规和监管规则的风险，将经济或声誉损失降到最低。', '金融合规智能监管平台既适用于金融监管机构实现合规监管功能，也适用于金融机构实现内部合规风险识别和预警'] },
            { class: 'title', text: ['两大核心场景'] },
            { class: 'content has-point', text: ['金融机构制定的制度和准则是否违反监管法律法规？', '下级部门制定的规章制度是否和上级部门同类文件冲突或者有遗漏？'] }
          ]
        }
      ],
      tabIndex: 0
    }
  },
  methods: {
    handleSelectTab (index: string) {
      this.tabIndex = index
      // console.log('[debug]', index, this.videos[index])
    }
  }
})

export default class NlpCase extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
#nlp-case{
}
</style>
