<template>
  <div id="case-comp">
    <div class="anchor"></div>
    <block-title :title="blockTitle"/>
    <div class="block-des part-bold">{{blockDes}}</div>
    <div class="wrapper">
      <img class="bg" src="@/assets/case/case-top-left.png" alt="">
      <case-tab-bar :tabs="tabsList" @select-tab="handleSelectTab"/>
      <el-row class="pane">
        <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
          <img-carousel class="img-crousel" :caseType="tabsContent[current].type" :scaleEnable="true" @click-img="handleClickImg"/>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="24">
          <div v-for="(item, index) in tabsContent[current].content" :key="index" :class="item.class">
            <div v-for="(subItem, subIndex) in item.text" :key="subIndex">{{subItem}}</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <img-dialog ref="imgDialog"/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import BlockTitle from '../public/BlockTitle.vue'
import CaseTabBar from '../public/CaseTabBar.vue'
import ImgCarousel from '../public/ImgCarousel.vue'
import ImgDialog from '../public/ImgDialog.vue'

@Options({
  props: {
    blockTitle: String,
    blockDes: String,
    tabsList: Array,
    tabsContent: Array
  },
  components: {
    BlockTitle,
    CaseTabBar,
    ImgCarousel,
    ImgDialog
  },
  data () {
    return {
      current: 0
    }
  },
  methods: {
    handleSelectTab (index: number) {
      this.current = index
      this.$emit('select-tab', index)
    },
    handleClickImg (type: string) {
      this.$refs.imgDialog && this.$refs.imgDialog.show(type)
    }
  }
})

export default class CaseComp extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
#case-comp{
  .block-des{
    margin: 20px auto;
    padding: 0 20px;
    text-align: left;
    font-size: 12px;
    line-height: $content-line-height;
    color: #2E2E2E;
  }
  .part-bold{
    span{
      // font-weight: bold;
    }
  }
  .wrapper{
    position: relative;
    .bg{
      position: absolute;
      top: 66px;
      left: -36px
    }
    .pane{
      height: 290px;
      margin-top: 20px;
      .el-col{
        padding: 0 10px;
        text-align: left;
        overflow: hidden;
        border-radius: 4px;
        img{
          width: 100%;
          transform: scale(1);
          transition: transform 0.4s;
          border-radius: 4px;
          cursor: pointer;
          &:hover{
            transform: scale(1.1);
            transition: transform 0.4s;
          }
        }
        .title{
          font-size: 14px;
          font-weight: bold;
          line-height: 30px;
          color: #2E2E2E;
        }
        .content{
          font-size: 12px;
          font-weight: 400px;
          line-height: $content-line-height;
          margin-bottom: 20px;
          color: #2E2E2E;
          div{
            margin-bottom: 5px;
          }
        }
        .has-point{
          div{
            padding-left: 20px;
            background: url('@/assets/case/dot.svg') no-repeat 0px 6px;
          }
        }
      }
    }
  }
}

@media(max-width:1080px){
  #case-comp{
    .wrapper{
      .pane{
        height: auto;
      }
    }
  }
}

@media(max-width:768px){
  #case-comp{
    .wrapper{
      .pane{
        .el-col{
          padding: 0 20px;
        }
        .img-crousel{
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
