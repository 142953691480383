<template>
  <div id="case-tab-bar">
    <div
      class="tab"
      :class="{'selected': current === index}"
      v-for="(tab, index) in tabs"
      :key="index"
      @mouseover="clickTab(index)"
      @click="clickTab(index)"
    >
      {{tab}}
      <div class="tag">
        <div></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    tabs: Array
  },
  data () {
    return {
      current: 0
    }
  },
  methods: {
    clickTab (index: number) {
      if (this.current === index) {
        return
      }
      this.current = index
      this.$emit('select-tab', index)
    }
  }
})

export default class CaseTabBar extends Vue {
  tabs!: Array<string>
}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
#case-tab-bar{
  width: 740px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  .tab{
    width: 200px;
    padding: 15px 0;
    // border-bottom: 1px solid #37373722;
    font-weight: 500;
    box-shadow: 0px 2px 12px 2px rgba(18, 0, 0, 0.2);
    box-sizing: border-box;
    color: #484848;
    background: #fff;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .tag{
      position: absolute;
      bottom: 0;
      height: 4px;
      width: 100%;
      display: none;
      div{
        width: 62%;
        height: 100%;
        margin: 0 auto;
        background: linear-gradient(90deg, #E1292B, #fff);
        border-radius: 2px;
        border-left: 18px solid #000;
        border-color: #000;
        box-sizing: border-box;
      }
    }
    &:hover{
    }
    &.selected{
      .tag{
        display: block;
      }
    }
  }
}
@media(max-width:767px){
  #case-tab-bar{
    width: 375px;
    box-sizing: border-box;
    padding: 10px;
    .tab{
      width: 80px;
      padding: 10px 5px;
      margin: 0 5px;
      .pane{
        .el-col{
          padding: 0 30px;
        }
      }
    }
  }
}
</style>
