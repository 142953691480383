<template>
  <div id="to-top" @click="clickScrollTop" v-show="visible">
    <i class="el-icon-download icon-class"></i>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'

@Options({
  data () {
    return {
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      scrollTop: 'scrollTop'
    })
  },
  watch: {
    scrollTop: {
      handler (val) {
        this.updateVisible(val, this)
      }
    }
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapMutations({
      updateScrollTop: 'updateScrollTop'
    }),
    updateVisible: _.throttle(function (val, ctx) {
      ctx.visible = val > 1080
    }, 300),
    handleScroll () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.updateScrollTop(scrollTop.toFixed(2))
    },
    clickScrollTop () {
      const element = document.getElementById('home-banner-block')
      element && element.scrollIntoView({ behavior: 'smooth' })
    }
  }
})

export default class ToTop extends Vue {}
</script>

<style scoped lang="scss">
@import '@/styles/index.scss';
#to-top{
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: rgba(238, 238, 238, 0.5);
  &:hover{
    background-color: rgba(238, 238, 238, 1);
  }
  .icon-class {
    margin-top: 8px;
    font-size: 24px;
    transform:rotate(180deg);
    -ms-transform:rotate(180deg); /* Internet Explorer */
    -moz-transform:rotate(180deg); /* Firefox */
    -webkit-transform:rotate(180deg); /* Safari 和 Chrome */
    -o-transform:rotate(180deg); /* Opera */
    color: $title-red;
  }
}
</style>
