<template>
  <div id="header-bar">
    <div class="wrapper">
      <div class="menus hidden-xs-only">
        <div
          class="col-5"
          :class="{'col-5-active': currentTab === item.id}"
          v-for="item in items"
          :key="item.id"
          @click="switchTab(item.id)">
            <div>{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import $ from 'jquery'

@Options({
  data () {
    return {
      items: [
        { name: '关于我们', id: 'about-us' },
        { name: '智能办公业务', id: 'nlp-case' },
        { name: '时空数据应用', id: 'gis-case' },
        { name: '行业经验', id: 'home-partner' },
        { name: '联系我们', id: 'footer-bar' }
      ],
      currentTab: ''
    }
  },
  methods: {
    clickEvent () {
      console.log('click button.')
      this.$router.push('/about')
    },
    switchTab (id: string) {
      this.currentTab = id
      const _offset = $(`#${id} .anchor`).offset()
      if (_offset) {
        const t = _offset.top - 40
        $(window).scrollTop(t)
      }
    },
    activeTab (tab: string) {
      if (!tab) {
        return
      }
      this.currentTab = tab
    }
  }
})
export default class HeaderBar extends Vue {
  title!: string
}
</script>

<style scoped lang="scss">
@import '@/styles/index.scss';
#header-bar{
  width: 100%;
  height: $header-bar-height;
  border-bottom: 1px solid #373737;
  box-sizing: border-box;
  .wrapper{
    height: 100%;
    margin: 0 68px;
    background: url('@/assets/bayes-logo-white.png') no-repeat left center;
    background-size: 80px;
    .menus{
      height: 100%;
      width: 600px;
      float: right;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .col-5{
        display: inline-block;
        width: 25%;
        height: 100%;
        text-align: center;
        cursor: pointer;
        color: #dfdfdf;
        border-bottom: 2px solid transparent;
        div{
          height: 100%;
          line-height: $header-bar-height;
        }
        &:hover{
          div{
            font-size: 18px;
            font-weight: bold;
            border-bottom: 2px solid $title-red;
          }
        }
      }
      .col-5-active{
        background: #1a2937;
        div{
          color: #fff;
          font-size: 18px;
          border-bottom: 2px solid $title-red;
        }
      }
    }
  }
}
@media(max-width: 1216px){ // 1080+68*2
  #header-bar{
    .wrapper{
      margin: 0 20px;
    }
  }
}
@media(max-width: 1080px){
  #header-bar{
    .wrapper{
      margin: 0 68px;
    }
  }
}
@media(max-width: 904px){ // 768+68
  #header-bar{
    .wrapper{
      margin: 0 20px;
    }
  }
}
@media(max-width: 767px){
  #header-bar{
    .wrapper{
      // background:url('../assets/bayes-logo.png') no-repeat 20px center;
      background-size: 70px;
      margin: 0 10px;
    }
  }
}
</style>
