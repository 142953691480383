
import { Options, Vue } from 'vue-class-component'
import BlockTitle from '../public/BlockTitle.vue'
import CaseTabBar from '../public/CaseTabBar.vue'
import ImgCarousel from '../public/ImgCarousel.vue'
import ImgDialog from '../public/ImgDialog.vue'

@Options({
  props: {
    blockTitle: String,
    blockDes: String,
    tabsList: Array,
    tabsContent: Array
  },
  components: {
    BlockTitle,
    CaseTabBar,
    ImgCarousel,
    ImgDialog
  },
  data () {
    return {
      current: 0
    }
  },
  methods: {
    handleSelectTab (index: number) {
      this.current = index
      this.$emit('select-tab', index)
    },
    handleClickImg (type: string) {
      this.$refs.imgDialog && this.$refs.imgDialog.show(type)
    }
  }
})

export default class CaseComp extends Vue {}
