<template>
  <header-bar
    class="header-bar"
    ref="headerBar"
    :style="customStyle"
    v-if="visible"/>
  <div class="main-area">
    <router-view @activeTab="handleActiveTab"/>
  </div>
  <footer-bar class="footer-bar" :title="msg" v-if="visible"/>
  <to-top class="to-top"/>
</template>

<script lang="ts">
import HeaderBar from './components/HeaderBar.vue'
import FooterBar from './components/FooterBar.vue'
import ToTop from '@/components/public/ToTop.vue'
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'

@Options({
  components: {
    HeaderBar,
    FooterBar,
    ToTop
  },
  data () {
    return {
      msg: '顶栏标题',
      customStyle: {},
      visible: false
    }
  },
  computed: {
    ...mapGetters({
      scrollTop: 'scrollTop'
    })
  },
  watch: {
    scrollTop: {
      handler (val) {
        const _topValue = Number(val)
        if (_topValue > 590) {
          this.customStyle = { background: '#373737', top: '0px', 'border-color': '#ccc' }
          return
        }
        this.customStyle = { top: `-${_topValue}px` }
      }
    },
    $route: {
      handler (val) {
        // console.log('$route', val)
        this.visible = this.$route.name === 'Home'
      },
      deep: true
    }
  },
  methods: {
    handleActiveTab (tab: string) {
      this.$refs.headerBar.activeTab(tab)
    }
  }
})

export default class App extends Vue {}
</script>

<style lang="scss">
#app {
  font-family: "Helvetica Neue",Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial, SimSun, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  .header-bar{
    position: fixed;
    left: 0;
    z-index: 1001;
  }
  .footer-bar{}
  .to-top{
    position: fixed;
    right: 30px;
    bottom: 100px;
    z-index: 999;
  }
  .main-area{
    height: calc(100% - 60px);
  }
}
</style>
