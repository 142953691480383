<template>
  <div
    id="img-carousel"
    :class="{'img-scale': scaleEnable}"
    @click="clickImg">
    <el-carousel ref="carousel" :height="carouselHeight" indicator-position="outside">
      <el-carousel-item v-for="(item, index) in list[caseType]" :key="index">
        <img :src="item" alt="" @mouseover="handleMouseover" @mouseleave="handleMouseleave">
      </el-carousel-item>
    </el-carousel>
    <div class="mask" v-if="scaleEnable && maskVisible">
      <i class="el-icon-zoom-in"></i>
    </div>
    <div class="btn" @click="handleClick" v-if="playerVisible">播放视频</div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import adambot1 from '@/assets/case/adambot1.png'
import adambot2 from '@/assets/case/adambot2.png'
import adambot3 from '@/assets/case/adambot3.png'
import flowchart1 from '@/assets/case/flowchart1.png'
import flowchart2 from '@/assets/case/flowchart2.png'
import flowchart3 from '@/assets/case/flowchart3.png'
import law1 from '@/assets/case/law1.png'
import law2 from '@/assets/case/law2.png'
import law3 from '@/assets/case/law3.png'
import meteo1 from '@/assets/case/meteo1.png'
import meteo2 from '@/assets/case/meteo2.png'
import meteo3 from '@/assets/case/meteo3.png'
import meteo4 from '@/assets/case/meteo4.png'
import shdx1 from '@/assets/case/shdx1.png'
import shdx2 from '@/assets/case/shdx2.png'
import shdx3 from '@/assets/case/shdx3.png'
import nankai1 from '@/assets/case/nankai1.png'
import nankai2 from '@/assets/case/nankai2.png'
import nankai3 from '@/assets/case/nankai3.png'

@Options({
  props: {
    caseType: String,
    scaleEnable: {
      type: Boolean,
      default: false
    },
    carouselHeight: {
      type: String,
      default: '270px'
    }
  },
  data () {
    return {
      list: {
        adambot: [adambot1, adambot2, adambot3],
        flowchart: [flowchart1, flowchart2, flowchart3],
        law: [law1, law2, law3],
        meteo: [meteo1, meteo2, meteo3, meteo4],
        shdx: [shdx1, shdx2, shdx3],
        nankai: [nankai1, nankai2, nankai3]
      },
      maskVisible: false,
      eventPrevent: false
    }
  },
  computed: {
    playerVisible () {
      return this.scaleEnable && ['adambot', 'flowchart', 'law'].find(el => el === this.caseType)
    } 
  },
  watch: {
    caseType: {
      handler () {
        console.log('caseType', this.caseType)
        this.$refs.carousel.setActiveItem(0)
        this.$forceUpdate() // 强制刷新UI，避免案例图片显示错位
      }
    }
  },
  methods: {
    clickImg () {
      if (!this.scaleEnable || this.eventPrevent) {
        this.eventPrevent = false
        return
      }
      this.$emit('click-img', this.caseType)
    },
    handleMouseover () {
      this.maskVisible = true
    },
    handleMouseleave () {
      this.maskVisible = false
    },
    handleClick () {
      // this.$router.push({ path: `/video/${this.tabsContent[index].type}` })
      this.eventPrevent = true
      window.open(`/player/${this.caseType}`, '_blank')
    }
  }
})

export default class ImgCarousel extends Vue {
  caseType!: string;
  carouselHeight!: string
}
</script>

<style lang="scss" scoped>
#img-carousel{
  height: 100%;
  position: relative;
  &.img-scale{
    img{
      transform: scale(1);
      transition: transform 0.4s;
      &:hover{
        transform: scale(1.2);
        transition: transform 0.4s;
      }
    }
  }
  img{
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
  }
  .mask{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: #ffffff99;
    display: flex;
    justify-content: center;
    cursor: pointer;
    pointer-events: none;
    i{
      font-size: 24px;
      font-weight: 500;
      color: #666;
      margin-top: 120px;
    }
  }
  .btn{
    position: absolute;
    top: 240px;
    right: 10px;
    z-index: 1000;
    display: inline-block;
    box-sizing: border-box;
    padding: 0 5px;
    height: 24px;
    line-height: 22px;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid #a3a3a3;
    color: #606266;
    background-color: #e4e4e494;
    cursor: pointer;
    &:hover{
      color: #fff;
      border-color: #E1292B55;
      background-color: #E1292B94;
    }
  }
}

@media(max-width:1080px){
  #img-carousel{
    .el-carousel{
      height: 260px;
    }
    .btn{
      top: 180px;
    }
  }
}

@media(max-width:767px){
  #img-carousel{
    .el-carousel{
      height: 260px;
    }
    &.img-scale{
      pointer-events: none;
      .mask{
        display: none;
      }
      .btn{
        pointer-events: auto;
      }
    }
    .btn{
      top: 230px;
    }
  }
}

@media(max-width:375px){
  #img-carousel{
    .el-carousel{
      height: 200px;
    }
    &.img-scale{
      pointer-events: none;
      .mask{
        display: none;
      }
      .btn{
        pointer-events: auto;
      }
    }
    .btn{
      top: 160px;
    }
  }
}
</style>

<style lang="scss">
@import '@/styles/index.scss';
#img-carousel{
  .el-carousel{
    .el-carousel__indicators{
      .el-carousel__indicator{
        .el-carousel__button{
          // background-color: #DBDBDB;
        }
        &.is-active{
          .el-carousel__button{
            background-color: $title-red;
          }
        }
      }
    }
  }
}
</style>
