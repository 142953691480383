<template>
  <div id="home">
    <div class="content-part1">
      <banner-block class="block"/>
      <div class="bottom-triangle"></div>
    </div>
    <div class="content-part2" ref="about-us">
      <about-us class="block"/>
    </div>
    <div class="content-part3" ref="nlp-case">
      <img src="@/assets/block-bg/nlp-bg-2.png" alt="">
      <nlp-case class="block"/>
    </div>
    <div class="content-part4" ref="gis-case">
      <img width="200" src="@/assets/block-bg/gis-bg-2.png" alt="">
      <gis-case class="block"/>
    </div>
    <div class="content-part5" ref="home-partner">
      <partner class="block"/>
    </div>
    <div class="content-part6" ref="footer-bar">
      <!-- <test-comp class="block"/> -->
    </div>
    <video-win class="video-win" v-if="videoWinVisible" @close="handleClose" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import BannerBlock from '@/components/home/BannerBlock.vue' // @ is an alias to /src
import AboutUs from '@/components/home/AboutUs.vue'
import NlpCase from '@/components/home/NlpCase.vue'
import GisCase from '@/components/home/GisCase.vue'
import Partner from '@/components/home/Partner.vue'
import TestComp from '@/components/TestComp.vue'
import VideoWin from '@/components/public/VideoWin.vue'
import { mapGetters } from 'vuex'

interface Data {
  name: string,
  id: string,
  top: number
}

@Options({
  components: {
    BannerBlock,
    AboutUs,
    NlpCase,
    GisCase,
    Partner,
    TestComp,
    VideoWin
  },
  data () {
    return {
      videoWinVisible: false,
      headerBarItems: [
        { name: '关于我们', id: 'about-us' },
        { name: '智能办公业务', id: 'nlp-case' },
        { name: '时空数据应用', id: 'gis-case' },
        { name: '行业经验', id: 'home-partner' },
        { name: '联系我们', id: 'footer-bar' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      scrollTop: 'scrollTop'
    })
  },
  watch: {
    scrollTop: {
      handler (val) {
        const _topValue = Number(val)
        this.activeTab(_topValue)
      }
    }
  },
  methods: {
    clickEvent () {
      this.$router.push('/about')
    },
    handlePlayVideo (url: string) {
      this.videoWinVisible = true
      console.log(url)
    },
    handleClose () {
      this.videoWinVisible = false
    },
    activeTab (scrollTop: number) {
      let tabsIndex = ''
      let btns = JSON.parse(JSON.stringify(this.headerBarItems))
      btns.map((data: Data) => {
        if (this.$refs[data.id]) {
          data.top = this.$refs[data.id].offsetTop || this.$refs[data.id].$el.offsetTop
        }
      })
      scrollTop += 100 // 补菜单高度，提前切换
      for (let index = 0; index < btns.length; index++) {
        const element = btns[index]
        const nextElement = btns[index + 1]
        if (nextElement) {
          if (scrollTop > element.top && scrollTop < (nextElement.top + element.top) / 2) {
            tabsIndex = btns[index].id
            break
          }
        } else {
          if (scrollTop > element.top) {
            tabsIndex = btns[index].id
            break
          }
        }
      }
      this.currentTab = tabsIndex
      // console.log(tabsIndex)
      this.$emit('activeTab', tabsIndex)
    }
  }
})

export default class Home extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
#home{
  .block{
    width: 1080px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .content-part1{
    background: #3B3B3B url('@/assets/banner/background@2x.png') no-repeat left top;
    background-size: 100% 100%;
  }
  .content-part3{
    background-color: #FCFCFC;
    margin-bottom: 0;
    padding-bottom: 40px;
    background: #FCFCFC url('@/assets/block-bg/nlp-bg-1.png') no-repeat left bottom;
    position: relative;
    // background-size: 80%;
    >img{
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  .content-part4{
    // background-color: #fbfbfb;
    margin-bottom: 0;
    padding-bottom: 40px;
    background: url('@/assets/block-bg/gis-bg-1.png') no-repeat left 120px;
    background-size: 200px;
    position: relative;
    >img{
      position: absolute;
      bottom: 30px;
      right: 0;
    }
  }
  .content-part5{
    background-color: #FCFCFC;
    margin-bottom: 0;
    padding-bottom: 40px;
  }
  .video-win {
    position: fixed;
    top: 200px;
    left: 10px;
    z-index: 1000;
  }
}

@media(max-width:1080px){
  #home{
    .block{
      width: 768px;
    }
    .content-part3{
      >img{
        display: none;
      }
    }
    .content-part4{
      background-position-y: 20px;
    }
  }
}

@media(max-width:767px){
  #home{
    .block{
      width: 100%;
    }
    .content-part1{
      background-size: 120% 50%;
      .bottom-triangle{
        width: 0;
        height: 0;
      }
    }
    .content-part4{
      background: none;
    }
  }
}
</style>
