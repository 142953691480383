<template>
  <div id='footer-bar'>
    <div class="anchor"></div>
    <el-row class='footer-content hidden-xs-only'>
      <el-col :xl='2' :lg='2' :md='1' :sm='1' :xs='24' class='space'>
        <div>--</div>
      </el-col>
      <el-col :xl='3' :lg='3' :md='4' :sm='4' :xs='24' class='logo'>
        <img style='width:100px' src='@/assets/bayes-logo.svg' @click="route('/index')">
      </el-col>
      <el-col :xl='6' :lg='6' :md='6' :sm='6' :xs='24' class='contact-us'>
        <div class='title'>联系我们/Contact us</div>
        <div class='company item'>
          <!-- <span>地址：</span> -->
          <span>西安贝业思数据智能技术服务有限公司</span>
        </div>
        <div class='address item'>
          <span>地址：</span>
          <span>西安市雁塔区科技三路57号融城云谷B座1007室</span>
        </div>
        <div class='site item'>
          <span>网址：</span>
          <span>www.bayesba.com</span>
        </div>
        <div class='tel item'>
          <span>电话：</span>
          <span>029-88222582</span>
        </div>
        <div class='mail item'>
          <span>邮箱：</span>
          <span>bayes@bayesba.com</span>
        </div>
      </el-col>
      <el-col :xl='12' :lg='12' :md='12' :sm='12' :xs='24' class='slogan-item'>
        <div class='slogan'>人工智能 · 数据驱动</div>
      </el-col>
    </el-row>
    <el-row class='copyright'>
      <el-col>
        Copyright © 2021 | 西安贝业思版权所有
        <span class="beianhao" @click="beianClick">陕ICP备19018134号</span>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
// import urls from '@/urls.js'
// import $ from 'jquery'
// import bus from '@/@/static/js/event-bus'
import { Options, Vue } from 'vue-class-component'

@Options({
  methods: {
    beianClick () {
      window.open('https://beian.miit.gov.cn/')
    }
  }
})

export default class ToTop extends Vue {}
</script>

<style lang='scss' scoped>
@import '@/styles/index.scss';
#footer-bar {
  // margin-top: 80px;
  color: #868686;
  line-height: $line-height-24;
  background: #24323e url('@/assets/footer/footer.jpg') no-repeat;
  background-size: cover;
  text-align: left;
  .footer-content {
    padding: 30px 60px;
    border-bottom: 1px solid #86878f;
    .space {
      opacity: 0;
    }
    .logo {
      img {
        cursor: pointer;
      }
    }
    .title {
      font-weight: 800;
      font-size: 20px;
      color: #eee;
      margin-bottom: 5px;
    }
    .sub-title {
      cursor: pointer;
      font-size: 12px;
      &:hover{
        opacity: 0.7;
      }
    }
    .contact-us {
      .item {
        font-size: 12px;
        overflow: hidden;
      }
      .address {
        background: url('@/assets/footer/address.svg') no-repeat left 3px;
        padding-left: 20px;
        padding-right: 20px;
      }
      .site {
        background: url('@/assets/footer/site.svg') no-repeat left 3px;
        padding-left: 20px;
      }
      .tel {
        background: url('@/assets/footer/tel.svg') no-repeat left 3px;
        padding-left: 20px;
      }
      .mail {
        background: url('@/assets/footer/mail.svg') no-repeat left 3px;
        padding-left: 20px;
      }
    }
    .slogan-item {
      .slogan {
        font-size: 28px;
        text-align: center;
        line-height: 160px;
      }
    }
  }
  .copyright {
    height: 60px;
    padding-top: 20px;
    font-size: 12px !important;
    text-align: center;
    .beianhao{
      margin-left: 10px;
      cursor: pointer;
      &:hover{
        color: white;
      }
    }
  }
}

@media (max-width: 1080px) {
  .footer-content {
    padding-left: 0 !important;
    img {
    }
  }
}

@media (max-width: 992px) {
  #footer-bar {
    .footer-content {
      #contact-us {
        .address {
          padding-right: 5px;
        }
      }
    }
  }
}
</style>
