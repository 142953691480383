<template>
  <div id="about-us">
    <div class="anchor"></div>
    <block-title title="关于我们" subTitle="国家级高新技术企业+大数据产业协会会员单位"/>
    <div class="sub-item">
      <div class="title">-公司简介</div>
      <div class="content">
        <about-compay/>
      </div>
    </div>
    <div class="sub-item">
      <div class="title">-企业文化</div>
      <div class="content">
        <about-culture/>
      </div>
    </div>
    <div class="sub-item">
      <div class="title">-企业优势</div>
      <div class="content">
        <about-superiority/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import BlockTitle from '../public/BlockTitle.vue'
import AboutCompay from '../about/AboutCompany.vue'
import AboutCulture from '../about/AboutCulture.vue'
import AboutSuperiority from '../about/AboutSuperiority.vue'

@Options({
  components: {
    BlockTitle,
    AboutCompay,
    AboutCulture,
    AboutSuperiority
  }
})

export default class AboutUs extends Vue {}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
#about-us{
  .sub-item{
    padding: 10px 0;
    .title{
      border-left: 5px solid $title-red;
      font-size: 16px;
      text-align: left;
      font-weight: bold;
      &::first-letter{
        color: transparent
      }
    }
    .content{
      padding: 10px 0;
    }
  }
}

@media(max-width: 767px){
  #about-us{
    .sub-item{
      .title{
        margin-left: 10px;
      }
    }
  }
}
</style>
