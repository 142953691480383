
import { Options, Vue } from 'vue-class-component'
import ImgCarousel from './ImgCarousel.vue'

@Options({
  components: {
    ImgCarousel
  },
  data () {
    return {
      dialogVisible: false,
      caseType: '',
      customStyle: {},
      carouselHeight: '400px'
    }
  },
  methods: {
    show (type: string) {
      // console.log('get bounds:', window.innerHeight, window.innerWidth)
      let _height = window.innerHeight
      let _width = window.innerWidth
      const _ratioWidth = _height * 12 / 7
      if (_ratioWidth < window.innerWidth) { // 窗口宽度足够
        _width = _ratioWidth
      } else {
        _width = window.innerWidth
        _height = _width * 7 / 12 // 基于窗口宽度，缩小高度
      }
      const _carouselHeight = _height - 100
      _width = _width - 100
      _height = _height - 160

      this.customStyle = { width: `${_width}px`, height: `${_height}px` }
      this.carouselHeight = `${_carouselHeight}px`

      this.caseType = type
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
      this.caseType = ''
    }
  }
})

export default class ImgDialog extends Vue {}
