
// import urls from '@/urls.js'
// import $ from 'jquery'
// import bus from '@/@/static/js/event-bus'
import { Options, Vue } from 'vue-class-component'

@Options({
  methods: {
    beianClick () {
      window.open('https://beian.miit.gov.cn/')
    }
  }
})

export default class ToTop extends Vue {}
