
import { Options, Vue } from 'vue-class-component'
import adambot1 from '@/assets/case/adambot1.png'
import adambot2 from '@/assets/case/adambot2.png'
import adambot3 from '@/assets/case/adambot3.png'
import flowchart1 from '@/assets/case/flowchart1.png'
import flowchart2 from '@/assets/case/flowchart2.png'
import flowchart3 from '@/assets/case/flowchart3.png'
import law1 from '@/assets/case/law1.png'
import law2 from '@/assets/case/law2.png'
import law3 from '@/assets/case/law3.png'
import meteo1 from '@/assets/case/meteo1.png'
import meteo2 from '@/assets/case/meteo2.png'
import meteo3 from '@/assets/case/meteo3.png'
import meteo4 from '@/assets/case/meteo4.png'
import shdx1 from '@/assets/case/shdx1.png'
import shdx2 from '@/assets/case/shdx2.png'
import shdx3 from '@/assets/case/shdx3.png'
import nankai1 from '@/assets/case/nankai1.png'
import nankai2 from '@/assets/case/nankai2.png'
import nankai3 from '@/assets/case/nankai3.png'

@Options({
  props: {
    caseType: String,
    scaleEnable: {
      type: Boolean,
      default: false
    },
    carouselHeight: {
      type: String,
      default: '270px'
    }
  },
  data () {
    return {
      list: {
        adambot: [adambot1, adambot2, adambot3],
        flowchart: [flowchart1, flowchart2, flowchart3],
        law: [law1, law2, law3],
        meteo: [meteo1, meteo2, meteo3, meteo4],
        shdx: [shdx1, shdx2, shdx3],
        nankai: [nankai1, nankai2, nankai3]
      },
      maskVisible: false,
      eventPrevent: false
    }
  },
  computed: {
    playerVisible () {
      return this.scaleEnable && ['adambot', 'flowchart', 'law'].find(el => el === this.caseType)
    } 
  },
  watch: {
    caseType: {
      handler () {
        console.log('caseType', this.caseType)
        this.$refs.carousel.setActiveItem(0)
        this.$forceUpdate() // 强制刷新UI，避免案例图片显示错位
      }
    }
  },
  methods: {
    clickImg () {
      if (!this.scaleEnable || this.eventPrevent) {
        this.eventPrevent = false
        return
      }
      this.$emit('click-img', this.caseType)
    },
    handleMouseover () {
      this.maskVisible = true
    },
    handleMouseleave () {
      this.maskVisible = false
    },
    handleClick () {
      // this.$router.push({ path: `/video/${this.tabsContent[index].type}` })
      this.eventPrevent = true
      window.open(`/player/${this.caseType}`, '_blank')
    }
  }
})

export default class ImgCarousel extends Vue {
  caseType!: string;
  carouselHeight!: string
}
