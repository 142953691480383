
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    videoURL: String
  },
  methods: {
    closeWin () {
      this.$emit('close')
    }
  }
})

export default class VideoWin extends Vue {
  videoURL!: string
}
