<template>
  <div id="banner-animate">
    <div class="animate-img">
      <img width="396" height="401" id="rotate-img" src="@/assets/banner/banner-rotate.png" alt="">
      <img width="396" class="static-img" src="../../assets/banner/banner-static.png" alt="">
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
})
export default class BannerAnimate extends Vue {}
</script>

<style lang="scss" scoped>
@keyframes rotating{
  from{transform:rotate(0)}
  to{transform:rotate(360deg)}
}

#banner-animate{
  float: right;
  .animate-img{
    width: 396px;
    height: 401px;
    background: url('@/assets/banner/banner-center-ball@2x.png') center no-repeat;
    background-size: 30%;
    position: relative;
    .static-img{
      position: absolute;
      left: 0;
      top: 10px;
    }
    #rotate-img{
      animation: rotating 10s linear infinite;
      //animation:rotating 5s infinite;
      -webkit-animation: rotating 10s linear infinite; /*Safari and Chrome*/
    }
  }
}
@media(max-width: 1080px){
  #banner-animate{
    .animate-img{
      background-size: 30%;
    }
  }
}

@media(max-width:767px){
  #banner-animate{
    .animate-img{
      background-size: 30%;
    }
  }
}
</style>
