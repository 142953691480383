
import { Options, Vue } from 'vue-class-component'

@Options({
  data () {
    return {
      list: [
        {
          text: '先进的数据分析',
          class: 'img-1'
        },
        {
          text: '高效的计算能力',
          class: 'img-2'
        },
        {
          text: '领先的算法模式',
          class: 'img-3'
        },
        {
          text: '丰富的行业经验',
          class: 'img-4'
        }
      ]
    }
  }
})

export default class AboutSuperiority extends Vue {}
